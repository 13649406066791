import React from 'react';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Amsterdam Chiropractic & Massage" />
    <div style={{ maxWidth: `550px`, marginBottom: `1.45rem`, margin: 'auto' }}>
      <Image />
    </div>
  </Layout>
);

export default IndexPage;
